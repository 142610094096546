<template>
    <div class="login-container">
        <div class="login-header">
            <v-img src="/img/login_bg.jpg"
                    gradient="to top, rgba(6, 62, 25, 0.91), rgba(38, 193, 99, 0.92)"
            ></v-img>
        </div>

        <div class="d-flex justify-center login-card-container">
            <v-form ref="form1" v-model="form1Valid" lazy-validation @submit="login" >
                <v-card max-width="400px" class="pa-6 pt-0 login-card" elevation="3">
                    <v-card-text class="text-center">
                        <img style="width: 200px"  src="/img/pp.jpg" />
                        <v-alert :style="{opacity:  wrongCredentials ? '1' : '0'}" text dense color="red">
                            <span class="caption">Email e/ou senha incorretos. Tente novamente</span>
                        </v-alert>

                            <v-text-field name="email" label="Email"
                                          placeholder=" " v-model="email"
                                          :rules="[rules.required, rules.email]"
                                          type="text"></v-text-field>
                            <v-text-field
                                    v-model="password"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[rules.required, rules.min]"
                                    :type="show1 ? 'text' : 'password'"
                                    label="Senha" placeholder=" "
                                    @click:append="show1 = !show1"
                            ></v-text-field>

                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" outlined color="primary" :disabled="loading" @click="login">Entrar</v-btn>
                    </v-card-actions>
                    <v-overlay z-index="1" absolute :value="loading" color="white" opacity="0.6">
                        <span>s</span>
                    </v-overlay>
                    <v-progress-linear :active="loading" absolute  bottom   color="deep-orange" indeterminate></v-progress-linear>
                </v-card>
            </v-form>
        </div>
    </div>
</template>

<script>
    import {AuthService} from '../service/AuthService';
    import localforage from 'localforage';
    import {UsuarioQueries} from '../queries/UsuarioQueries';

    export default {
        name: 'Login',
        data() {
            return{
                show1: false,
                loading: false,
                wrongCredentials: false,
                email: null,
                password: null,
                form1Valid: true,
                rules: {
                    required: (value) => !!value || 'Campo Obrigatório.',
                    min: (v) => v && v.length >= 8 || 'Mínimo 8 caracteres',
                    email: (v) => /.+@.+\..+/.test(v) || 'E-mail deve ser válido',
                },

            };
        },
        mounted () {
            AuthService.getCurrentUser()
                .then((usuario) => {
                    if(usuario){
                        this.$router.replace('/admin');
                    }
                })

            // get return url from route parameters or default to '/'
            this.returnUrl = this.$route.query.returnUrl || '/admin';
        },
        methods: {
            login() {
                this.wrongCredentials = false;
                if (!this.$refs.form1.validate()) {
                    return;
                }
                this.loading = true;
                this.$apollo.provider.defaultClient.clearStore();
                AuthService.login(this.email, this.password)
                    .then(() => {
                        return AuthService.fetchUsuario(this.$apollo);
                    })
                    .then((usuario) => {
                        if (usuario) {
                            if (window.PasswordCredential) {
                                let passwordCredential = new PasswordCredential({
                                    id: this.email,
                                    password: this.password
                                });
                                navigator.credentials.store(passwordCredential);
                            }
                            if(this.$route.query.returnUrl){
                                this.$router.replace({path: this.returnUrl});
                            }else{
                                this.$router.replace({name: 'dashboard'});
                            }
                        } else {
                            this.$router.replace({name: 'newPassword'});
                        }
                    }).catch((error) => {
                        this.loading = false;
                        if (error.status) {
                            this.wrongCredentials = true;
                        } else {
                            // @ts-ignore
                            Vue.prototype.$dialog.message.error(error);
                        }
                    });
            },
        },

    };
</script>

<style>
    .login-container{
        height: 100%;
    }
    .login-header,
    .login-header .v-image{
        height: 300px;
    }
    .login-card-container{
        margin-top: -150px
    }
    @media(max-width: 600px) {
        .login-container{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .login-header{
            position: fixed;
        }
        .login-header,
        .login-header .v-image{
            height: 100%;
        }
        .login-card-container{
            margin-top: unset;
            padding: 20px;
            position: absolute;
        }
    }


    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
    }
</style>
